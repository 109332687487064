<template>
  <div :class="layout + 'columns row'">
    <slot name="columnLeft"></slot>
    <slot name="columnRight"></slot>
  </div>
</template>

<script>
export default {
  name: "Columns",
  props: {
    layout: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "/Columns";
</style>
