<template>
  <div class="wrapper form-page">
    <Section>
      <HeadingBlock :data="pageContent" />
    </Section>
    <Section>
      <Columns>
        <template v-slot:columnLeft>
          <div>
            <img :src="image" style="width: 100%; margin: 0 auto" />
          </div>
        </template>
        <template v-slot:columnRight>
          <Form
            class="form-fw"
            :data="form"
            :id="form.id"
            form="testDrive"
            @action="changeImage"
            @changeOption="changeOption"
          />
        </template>
      </Columns>
    </Section>
  </div>
</template>

<script>
import Columns from "@/components/Columns/Columns.vue";
import Form from "@/components/Forms/Form.vue";
import Section from "@/components/Section/Section.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import { getModels } from "@/services/functions";

import FormConfiguration from "@/data/FormsDev.json";

export default {
  name: "TestDrive",
  components: {
    Columns,
    Section,
    Form,
    HeadingBlock,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      form: {},
      isLexus: this.$store.state.isLexus,
      image: "",
      dealers: [],
      models: [],
    };
  },
  mounted() {
    this.image = this.isLexus
      ? require(`@/assets/${"lexus-logo-light.png"}`)
      : require(`@/assets/${"toyota-logo.jpg"}`);
    this.form = FormConfiguration.testDrive;
    this.$store.state.allInstallations.forEach((element) => {
      if (element.designation != undefined || element.objectId != undefined) {
        this.dealers.push({
          label: element.designation + " - " + element.postalCodeDesig,
          value: element.objectId,
        });
      }
    });
    this.form.fields.find((el) => el.name == "oidDealer").options =
      this.dealers;
    this.getModel();
  },
  computed: {
    dealerDetail() {
      return this.$store.state.allInstallations;
    },
    banner() {
      return this.$store.state.testdrive.image_banner;
    },
    pageContent() {
      return {
        blockTitle: this.$store.state.testdrive.title_block,
        blockText: this.$store.state.testdrive.text_block,
      };
    },
    seo() {
      return this.$store.state.homepage.seo;
    },
    placeHolder() {
      return this.$store.state.isLexus
        ? require("@/assets/unavailable-lexus.png")
        : require("@/assets/unavailable-toyota.png");
    },
  },
  methods: {
    getModels,
    changeImage(e) {
      this.image = e;
    },
    getModel() {
      let models = [];
      //document.querySelector("[name='model']").disabled = true;
      this.getModels().then((response) => {
        models = response.data;
        models.forEach((el) => {
          this.models.push({
            label: el.model,
            value: el.model,
            imageUrl: el.imageUrl ? el.imageUrl : this.placeHolder,
          });
        });
        this.models.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        );

        this.form.fields.find((el) => el.name == "model").options = this.models;
        document.querySelector("[name='model']").disabled = false;
      });
    },
    changeOption(e) {
      this.dealerDetail.forEach((element) => {
        if (element.objectId == e) {
          element.contacts[0].contactPersons[0].contactList.forEach((el) => {
            if (el.designation == "E-mail") {
              let customLead = document.getElementById(
                "test-drive-custom_lead"
              );
              customLead.value = el.value;
            }
          });
        }
      });
      this.models.forEach((element) => {
        if (element.value == e) {
          this.image = element.imageUrl;
        }
      });
    },
  },
};
</script>
